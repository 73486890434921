<template>
  <div>
    <div ref="slider" class="relative h-2 w-full rounded-full bg-blue-200" @mousedown="startDragging" @touchstart="startDragging">
      <span
        class="absolute top-0 z-10 -ml-2 -mt-1.5 h-5 w-5 cursor-pointer rounded-full bg-white shadow shadow-black/40"
        :style="`left: ${percent}%`"
      ></span>
      <span class="absolute left-0 top-0 h-2 rounded-full bg-blue-400" :style="`width: ${percent}%`"></span>
    </div>

    <div class="mt-2 flex justify-between text-blue-400">
      <span>${{ formatLabel(range[0]) }}</span>
      <span>${{ formatLabel(range[1]) }}</span>
    </div>

    <div class="text-center text-xl font-semibold leading-none text-blue-700">{{ formatCurrency(modelValue) }}</div>
  </div>
</template>

<script>
export default {
  props: {
    increment: {
      type: Number,
      default: 100000,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    range: {
      type: Array,
      default: () => [0, 20000000],
    },
  },

  data() {
    return {
      value: 0,
      dragging: false,
    }
  },

  computed: {
    percent() {
      return (this.modelValue / this.range[1]) * 100
    },
  },

  watch: {
    modelValue(newVal) {
      this.value = (newVal / this.range[1]) * 100
    },
  },

  mounted() {
    this.value = (this.modelValue / this.range[1]) * 100
  },

  methods: {
    formatCurrency(str) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })

      return formatter.format(str)
    },

    formatLabel(value) {
      if (value >= 1000000) {
        return value / 1000000 + 'm'
      } else if (value >= 1000) {
        return value / 1000 + 'k'
      } else {
        return value.toLocaleString()
      }
    },

    startDragging(event) {
      this.dragging = true

      if (event.type === 'mousedown') {
        document.addEventListener('mousemove', this.onMouseMove)
        document.addEventListener('mouseup', this.stopDragging)

        this.updateValue(event)
      } else if (event.type === 'touchstart') {
        document.addEventListener('touchmove', this.onTouchMove)
        document.addEventListener('touchend', this.stopDragging)

        this.updateValue(event.touches[0])
      }
    },

    onMouseMove(event) {
      if (this.dragging) {
        this.updateValue(event)
      }
    },

    onTouchMove(event) {
      if (this.dragging) {
        // Use event.touches[0] to get the first touch point
        this.updateValue(event.touches[0])
      }
    },

    stopDragging() {
      this.dragging = false

      document.removeEventListener('mousemove', this.onMouseMove)
      document.removeEventListener('mouseup', this.stopDragging)
      document.removeEventListener('touchmove', this.onTouchMove)
      document.removeEventListener('touchend', this.stopDragging)
    },

    updateValue(event) {
      const rect = this.$refs.slider.getBoundingClientRect()
      const offsetX = event.clientX - rect.left
      let newValue = (offsetX / rect.width) * 100
      newValue = Math.max(0, Math.min(newValue, 100)) // Clamp value between 0 and 100

      // Calculate the dollar value and round to the nearest increment
      let dollarValue = (newValue / 100) * this.range[1]
      dollarValue = Math.round(dollarValue / this.increment) * this.increment

      // Convert back to percentage
      newValue = (dollarValue / this.range[1]) * 100

      this.value = newValue
      this.$emit('update:modelValue', dollarValue)
    },
  },
}
</script>
